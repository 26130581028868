import { graphql, useStaticQuery, Link } from "gatsby"
import React from "react"
import tw, { styled } from "twin.macro"

const Section = styled.section`
  ${tw`relative z-20 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
`
const Teaser = styled.div`
  ${tw`relative flex flex-col h-[415px]`}
`
const HeadlineWrapper = styled.div`
  ${tw`relative z-10 flex justify-center items-center font-bold font-ubuntu h-1/2`}
`
const TextWrapper = styled.div`
  ${tw`relative h-1/2 px-5 py-10`}
`
const Headline = styled.h2`
  ${tw`relative uppercase text-[30px] px-5 text-center`}
`
const TextContent = styled.p`
  ${tw`relative font-firacode text-16 leading-7 text-center`}
`
const LinkArrow = styled.p`
  ${tw`absolute h-auto bottom-4 right-3 text-20 font-firacode`}
`

const WpAcfHowToArchiveBlock = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpHowTo {
        edges {
          node {
            uri
            title
            slug
            acfHowToTeasers {
              archiveTeaser {
                headline
                textContent
              }
            }
          }
        }
      }
    }
  `)

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "..."
    }
    return text
  }

  return (
    <Section className="how-tos-section">
      {data.allWpHowTo.edges.map(({ node }, index) => {
        const { headline, textContent } =
          node.acfHowToTeasers.archiveTeaser
        return (
          <Link 
            className="how-to-link"
            to={node.uri} 
            key={node.slug} 
            css={tw`no-underline`}>
            <Teaser 
              className="how-to-teaser"
              key={node.slug}>
                  <HeadlineWrapper>
                    <Headline>{headline != null ? headline : node.title}</Headline>
                  </HeadlineWrapper>
                  <TextWrapper>
                    <TextContent>{
                      truncateText(textContent, 100)
                      }</TextContent>
                    <LinkArrow>
                      ⟶
                    </LinkArrow>
                  </TextWrapper>
            </Teaser>
          </Link>
        )
      })}
    </Section>
  )
}

export default WpAcfHowToArchiveBlock
